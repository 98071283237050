<template>
    <div class="mb-5">
        <a-table :dataSource="cardList" :columns="columns" :pagination="false" rowKey="id">
            <template #radio="record">
                <a-radio :value="record.id" @change="onChange" :checked="record.id === selectedPaymentMethod" />
            </template>
            <template #brand="record">
                <div>
                    <img v-if="record.details.ccBrand === 'visa'" alt="Visa" src="@/assets/visa.svg" width="50px" />
                    <img v-else-if="record.details.ccBrand === 'mc'" alt="MasterCard" src="@/assets/mc.svg"
                        width="50px" />
                    <img v-else-if="record.details.ccBrand === 'amex'" alt="American Express" src="@/assets/amex.svg"
                        width="50px" />
                </div>
            </template>
            <template #cardNumber="record">
                <span>{{ cardNumberDetails(record.details) }}</span>
            </template>
            <template #cardName="record">
                <span>{{ record.details.cardName }}</span>
            </template>
            <template #expiry="record">
                <span>{{ record.details.expiry }}</span>
            </template>
            <template #actions="record">
                <div class="dF">
                    <div class="mr-4 edit-delete" @click="editCard(record)">
                        <a-icon type="edit" />
                    </div>
                    <div class="edit-delete" @click="deleteCard(record)" style="color: var(--danger)">
                        <a-icon type="delete" style="color:red" />
                    </div>
                </div>
            </template>
        </a-table>
        <h5 class="pl-3 pt-3" style="color: var(--orange); cursor: pointer" @click="openAddPaymentMethodModal">
            <a-icon type="plus-circle" /> &nbsp;&nbsp;Add a payment method
        </h5>

        <a-modal :footer="null" :centered="true" :visible="switchCardModal.visible" :width="720"
            @cancel="closeSwitchCardModal">
            <h5 class="mb-3">Update Payment Method</h5>
            <div class="text-dark mb-5" v-if="Object.keys(switchCardModal.card).length">
                {{ `Choose a new payment method in replace of ${getCardBrand(switchCardModal.card.details.ccBrand)}
                ${getCardNumber(switchCardModal.card.details.cardNo)}` }}
            </div>
            <div :style="paymentI == 0 ? 'margin-top:1rem' : ''" style="display: block"
                v-for="(payment, paymentI) in paymentMethodList" :key="payment.value + paymentI" :value="payment.value">
                <div class="dF aC" style="justify-content: space-between">
                    <div class="dF aC">
                        <a-radio :checked="switchCardModal.option == payment.value" @change="changeRadio"
                            :value="payment.value" />
                        <div>
                            <div>{{ payment.label }}</div>
                            <div @click="updateCard(payment.value)" v-if="paymentI != cardList.length - 1"
                                style="color: var(--orange); cursor: pointer;">
                                Update card info
                            </div>
                        </div>
                    </div>
                    <i @click="updateCard(payment.value)" v-if="paymentI != cardList.length - 1"
                        style="color: var(--orange); cursor: pointer" class="fe fe-edit-2" />
                </div>
                <hr v-if="paymentI != cardList.length - 1" />
            </div>
            <div class="mt-5 dF aC legals">
                By continuing, you agree to the Bildhive&nbsp;
                <a href="https://admin.bildhive.dev/system/terms" target="_">Terms of Service</a>.
            </div>
            <div class="dF aC mt-5 dF jE">
                <a-button class="mr-3" @click="closeSwitchCardModal">CANCEL</a-button>
                <a-button :loading="switchCardModal.loading" @click="updatePaymentMethodCard"
                    type="primary">UPDATE</a-button>
            </div>
        </a-modal>
        <BillingModal v-if="paymentMethodModal.show" :show="paymentMethodModal.show" :load="paymentMethodModal.load"
            :action="paymentMethodModal.action" :modalTitle="paymentMethodModal.title"
            :modalOkText="paymentMethodModal.okText" :paymentMethodData="paymentMethodToUpdate"
            @close="resetPaymentMethodModalData" @cardPayment="updatePaymentMethod">
        </BillingModal>
    </div>
</template>

<script>
import BillingModal from "@/views/projects/billingmodal";
import valid from "card-validator";

export default {
    components: { BillingModal },

    props: {
        paymentMethods: {
            type: Array,
            default: () => []
        },
        selectedPaymentMethod: {
            type: String,
            default: null
        },
    },

    data() {
        return {
            loadModal: false,
            confirmLoading: false,
            cardList: [],
            paymentMethodToUpdate: null,
            switchCardModal: {
                card: {},
                visible: false,
                option: "",
                loading: false,
            },
            paymentMethodModal: {
                show: false,
                load: false,
                title: "Add New Card",
                okText: "Add Card",
                action: "add",
                paymentMethodId: "",
            },
            columns: [
                { title: '', key: 'radio', scopedSlots: { customRender: "radio" }, width: 50 },
                { title: '', key: 'brand', scopedSlots: { customRender: "brand" }, width: 100 },
                { title: 'Credit Card Number', key: 'cardNumber', scopedSlots: { customRender: "cardNumber" } },
                { title: 'Cardholder Name', key: 'cardName', scopedSlots: { customRender: "cardName" } },
                { title: 'Expiry Date', key: 'expiry', scopedSlots: { customRender: "expiry" } },
                { title: '', key: 'actions', scopedSlots: { customRender: "actions" }, width: 100 }
            ]
        };
    },
    computed: {
        paymentMethodList() {
            let options = [];
            if (
                this.cardList &&
                this.cardList.length != 0 &&
                this.switchCardModal &&
                this.switchCardModal.card &&
                Object.keys(this.switchCardModal.card).length != 0
            ) {
                this.cardList.forEach((pay) => {
                    let obj = {};
                    if (pay.details.ccBrand == "visa") obj.name = "Visa";
                    else if (pay.details.ccBrand == "mc")
                        obj.name = "Mastercard";
                    else if (pay.details.ccBrand == "amex")
                        obj.name = "American Express";
                    obj.label = `${obj.name} ${this.getCardNumber(
                        pay.details.cardNo
                    )}`;
                    obj.value = pay.id;
                    if (obj.value != this.switchCardModal.card.id)
                        options.push(obj);
                });
                let newCard = {
                    label: "Add credit or debit card",
                    value: "newCard",
                };
                options.push(newCard);
            }
            return options;
        },
    },
    methods: {
        onChange(event) {
            this.$emit("onChange", event.target.value);
        },
        cardNumberDetails(card) {
            if (card) {
                return `${this.getCardBrand(card.ccBrand)} ending in ${card.cardNo.slice(-4)}`;
            }
        },
        getCardBrand(brand) {
            switch (brand) {
                case 'visa': return 'Visa';
                case 'mc': return 'MasterCard';
                case 'amex': return 'American Express';
                default: return '';
            }
        },
        editCard(item) {
            this.paymentMethodModal = {
                ...this.paymentMethodModal,
                action: "edit",
                okText: "Update",
                title: "Edit Card Details",
                paymentMethodId: item.id,
                show: true
            };
            this.paymentMethodToUpdate = item.details;
        },
        resetPaymentMethodModalData() {
            this.paymentMethodModal = {
                ...this.paymentMethodModal,
                action: "add",
                okText: "Add Card",
                title: "Add New Card",
                paymentMethodId: "",
                show: false,
                load: false
            };
            this.paymentMethodToUpdate = null;
            this.loadModal = false;
        },
        getCardNumber(string) {
            return `****${string.slice(-4)}`;
        },
        async deleteCard(card) {
            if (card.instances && card.instances.length > 0) {
                this.switchCardModal = { ...this.switchCardModal, visible: true, card };
            } else {
                this.$confirm({
                    title: "Delete Payment Method",
                    content: (h) => <div>Do you want to delete this card?</div>,
                    okText: "DELETE",
                    okType: "danger",
                    cancelText: "CANCEL",
                    centered: true,
                    onOk: () => this.confirmDeleteCard(card),
                    onCancel: () => console.log("Cancel")
                });
            }
        },
        async confirmDeleteCard(card) {
            try {
                const { data } = await this.$api.delete(`/payment-methods/${card.id}`);
                this.cardList = this.cardList.filter(x => x.id !== data.id);
            } catch (err) {
                this.$toastError(err, "An error occurred while deleting payment method. Please try again.")
            }
        },
        async updatePaymentMethod(paymentMethodDetails) {
            const expiry = valid.expirationDate(paymentMethodDetails.expiry);
            const sendCardOBJ = { ...paymentMethodDetails, expiry: `${expiry.month}/${expiry.year}` };

            this.loadModal = true;
            this.confirmLoading = true;
            this.paymentMethodModal.load = true;

            if (this.paymentMethodModal.action === "add") {
                await this.addPaymentMethod(sendCardOBJ);
            } else if (this.paymentMethodModal.action === "edit") {
                await this.editPaymentMethod(sendCardOBJ);
            }
        },
        async addPaymentMethod(sendCardOBJ) {
            try {
                const { data } = await this.$api.post("/payment-methods", { details: sendCardOBJ, default: sendCardOBJ.default || false });
                if (data.success === false) {
                    data.result.message.split("\n").forEach(mess => this.$message.error(mess));
                } else {
                    this.$message.success('Payment method added successfully!');
                    this.cardList.push(data);
                    this.resetPaymentMethodModalData();
                }
            } catch (err) {
                this.loadModal = false;
                this.$toastError(err, `Error while creating payment method. Try Again!`)
            }
        },
        async editPaymentMethod(sendCardOBJ) {
            try {
                const { data } = await this.$api.put(`/super-admin/payment-methods/${this.paymentMethodModal.paymentMethodId}`, { details: sendCardOBJ, default: sendCardOBJ.default || false });
                this.confirmLoading = false;
                this.loadModal = false;

                if (data.success === false) {
                    data.result.message.split("\n").forEach(mess => this.$message.error(mess));
                } else {
                    this.$message.success("Payment method details updated successfully.");
                    this.cardList = this.cardList.map(pm => pm.id === data.id ? data : pm);
                    this.resetPaymentMethodModalData();
                }
            } catch (err) {
                this.confirmLoading = false;
                this.loadModal = false;
                this.$toastError(err, `Error while updating payment method. Please try again!`)
            }
        },
        openAddPaymentMethodModal() {
            this.resetPaymentMethodModalData();
            this.paymentMethodModal.show = true;
        },
        closeSwitchCardModal() {
            this.switchCardModal.visible = false;
        },
        changeRadio(event) {
            this.switchCardModal.option = event.target.value;
        },
        updateCard(id) {
            let found = this.cardList.find((x) => x.id == id);
            if (found) {
                this.editCard(found)
            }
        },
        async updatePaymentMethodCard() {
            if (this.switchCardModal.option == "")
                return this.$message.error("Please select an option.");
            else if (this.switchCardModal.option == "newCard") {
                this.resetPaymentMethodModalData();
                this.paymentMethodModal.show = true;
            } else {
                this.switchCardModal.loading = true;
                try {
                    const { data } = await this.$api.post(`/payment-methods/${this.selectedInvoice.instance.id}/change-card/${this.switchCardModal.option}`);
                    this.$message.success("Successfully updated selected payment method");
                    this.$emit("onChange", data.paymentMethod);
                    this.closeSwitchCardModal();
                } catch (err) {
                    this.switchCardModal.loading = false;
                    this.$toastError(err, "Error while updating payment method for the instance. please try again!")
                }
            }
        },
    },
    created() {
        this.cardList = this.paymentMethods;
        let found = this.cardList.find((x) => x.id == this.selectedPaymentMethod);
        if(!found) {
            this.$emit("onChange", null);
        }
    },
};
</script>

<style scoped>
.edit-delete {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.65);
    transition: color 0.1s ease-in;
}
</style>
